<template>
    <div>
        <el-form :model="form_data" :rules="form_rules" :ref="form_name" :disabled="formDisabled" size="mini" label-position="right" label-width="100px">
            <el-form-item label="教练名称:" prop="coach_name" required><el-input v-model="form_data.coach_name"></el-input></el-form-item>
            <el-form-item label="头衔:" prop="coach_tag" required>
                <el-select v-model="form_data.coach_tag" filterable placeholder="请选择头衔">
                    <el-option v-for="(p) in params.coach_tag" :key="p.key" :label="p.value" :value="p.key"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="擅长:" prop="expert" required><el-input v-model="form_data.expert"></el-input></el-form-item>
            <el-form-item label="介绍:" prop="about" required>
                <el-input v-model="form_data.about" type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="头像:" prop="avatar" required>
                <el-upload
                        class="avatar-uploader"
                        :action="uploadUrl"
                        :headers="uploadHeader"
                        :show-file-list="false"
                        :limit="1"
                        :on-preview="handleAvatarPreview"
                        :on-remove="handleAvatarRemove"
                        :on-success="onAvatarUploadSuccess"
                        :before-upload="beforeAvatarFileUpload"
                >
                    <div style="width:80px;height:80px">
                        <img style="width:100%;" v-if="form_data.avatar" :src="imgHost+form_data.avatar" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </div>
                </el-upload>
            </el-form-item>
            <el-form-item label="顶图:" prop="banner" required>
                <el-upload
                        class="avatar-uploader"
                        :action="uploadUrl"
                        :headers="uploadHeader"
                        :show-file-list="false"
                        :limit="1"
                        :on-preview="handleBannerPreview"
                        :on-remove="handleBannerRemove"
                        :on-success="onBannerUploadSuccess"
                        :before-upload="beforeBannerFileUpload"
                >
                    <div style="height:120px;width:auto">
<!--                        <img style="width:100%;" v-if="form_data.banner" :src="imgHost+form_data.banner" class="avatar">-->
                        <img style="height:100%;" v-if="form_data.banner" :src="imgHost+form_data.banner" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </div>
                </el-upload>
            </el-form-item>
            <el-form-item label="封面:" prop="cover">
                <el-upload
                        class="avatar-uploader"
                        :action="uploadUrl"
                        :headers="uploadHeader"
                        :show-file-list="false"
                        :limit="1"
                        :on-preview="handleCoverPreview"
                        :on-remove="handleCoverRemove"
                        :on-success="onCoverUploadSuccess"
                        :before-upload="beforeCoverFileUpload"
                >
                    <div style="height:120px;width:auto">
                        <img style="height:100%;" v-if="form_data.cover" :src="imgHost+form_data.cover" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </div>
                </el-upload>
            </el-form-item>
            <el-form-item label="备注:" prop="remark"><el-input v-model="form_data.remark"></el-input></el-form-item>
            <el-form-item label="排序:" prop="sort"><el-input-number v-model="form_data.sort" :step="1"></el-input-number></el-form-item>
        </el-form>
        <div style="text-align:right;">
            <el-button v-if="action!=='view'" type="primary" @click="submitForm(form_name)" size="mini">提交编辑</el-button>
            <el-button @click="onCloseDialog" size="mini">关闭</el-button>
        </div>
    </div>
</template>

<script>
    import {getParams} from "@/api/hado/param";
    import{getDetail,submit} from "@/api/hado/coach";
    import {getToken} from "@/utils/auth";
    const param_list = ['coach_tag']

    export default {
        name: "coach-detail",
        props:{
            action:{
                value:String,
                default:'add'
            },
            rowId:{
                value:String,
            }
        },
        data(){
            return{
                form_name:'play_schedule_detail',
                form_data:{},
                form_rules:{},
                params:{},
                params_map:{},
                params_map_reverse:{},


                imgHost:process.env.VUE_APP_BASE_API,
                uploadUrl:process.env.VUE_APP_BASE_API+'/api/sys/file/upload',
                uploadHeader:{},
                imgDialogDisabled:false,
                dialogImageUrl:'',
            }
        },
        computed:{
            formDisabled(){
                return this.action === 'view' || !this.action;
            }
        },
        mounted() {
            this.uploadHeader['Authorization'] = 'Bearer ' + getToken();
        },
        created(){
            this.initPage()
        },
        methods:{
            initParams(){
                return new Promise((resolve,reject)=>{
                    getParams(param_list.join(','),'zh-CN').then(resp=>{
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl=>{
                            console.log('pl:',pl)
                            let tmp_map = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm=>{
                                // tmp_map[pm.key]=pm.value
                                tmp_map[pm.key]={value:pm.value,color:pm.color}
                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value]=pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_reverse = tmp_params_map_reverse
                        console.log('params_map:',this.params_map)
                        console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err)=>{
                        if(err){
                            console.error(err)
                        }
                        reject(err)
                    })
                });
            },
            initPage(){
                this.initParams().then(()=>{
                    if(this.rowId>0){
                        getDetail(this.rowId).then(resp=>{
                            this.form_data = resp.data
                        })
                    }
                })
            },
            onCloseDialog(){
                this.$emit("onTellDialogClose")
            },
            submitForm(formName){
                this.$refs[formName].validate((valid)=>{
                    if(valid){
                        submit(this.form_data).then(()=>{
                            this.$emit('onTellDialogClose')
                        })
                    }
                })
            },
            //图片处理
            //
            beforeAvatarFileUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isPNG = file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG&&!isPNG) {
                    this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return (isJPG||isPNG) && isLt2M;
            },
            handleAvatarPreview(file){
                console.log(file)
            },
            handleAvatarRemove(file,fileList){
                console.log(file,fileList)
            },
            onAvatarUploadSuccess(response, file, fileList){
                console.log(response,file,fileList)
                if(response.code == 200){
                    this.form_data.avatar = response.data.url;
                    console.log(this.form_data.avatar)
                    console.log(this.imgHost+this.form_data.avatar)
                    this.$forceUpdate() //强制i更新
                }
            },

            beforeCoverFileUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isPNG = file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG&&!isPNG) {
                    this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return (isJPG||isPNG) && isLt2M;
            },
            handleCoverPreview(file){
                console.log(file)
            },
            handleCoverRemove(file,fileList){
                console.log(file,fileList)
            },
            onCoverUploadSuccess(response, file, fileList){
                console.log(response,file,fileList)
                if(response.code == 200){
                    this.form_data.cover = response.data.url;
                    console.log(this.form_data.cover)
                    console.log(this.imgHost+this.form_data.cover)
                    this.$forceUpdate() //强制i更新
                }
            },

            beforeBannerFileUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isPNG = file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG&&!isPNG) {
                    this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return (isJPG||isPNG) && isLt2M;
            },
            handleBannerPreview(file){
                console.log(file)
            },
            handleBannerRemove(file,fileList){
                console.log(file,fileList)
            },
            onBannerUploadSuccess(response, file, fileList){
                console.log(response,file,fileList)
                if(response.code == 200){
                    this.form_data.banner = response.data.url;
                    console.log(this.form_data.banner)
                    console.log(this.imgHost+this.form_data.banner)
                    this.$forceUpdate() //强制i更新
                }
            },
        },



    }
</script>

<style scoped>

</style>
